.stepper {
  position: relative;
  width: 100%;
  padding: 0.5rem 0;

  &__container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 1rem;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__steps {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  &__step-wrapper {
    display: flex;
    align-items: center;
    flex: 1;

    &:last-child {
      flex: 0;
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
    cursor: pointer;
    outline: none;

    &:hover {
      .stepper__step-circle:not(.stepper__step-circle--error) {
        transform: scale(1.1);
      }
    }

    &:focus-visible {
      .stepper__step-circle:not(.stepper__step-circle--error) {
        box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.25);
      }
    }
  }

  &__step-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 2px solid #e3e6ed;
    font-weight: 500;
    color: #9da9bb;
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
    margin-bottom: 0.5rem;

    &--active {
      border-color: #0d6efd;
      color: #0d6efd;
      box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.1);
    }

    &--completed {
      background: #0d6efd;
      border-color: #0d6efd;
      color: white;
    }

    &--error {
      background: #dc3545;
      border-color: #dc3545;
      color: white;
      box-shadow: 0 0 0 4px rgba(220, 53, 69, 0.1);

      &:hover {
        transform: none;
      }
    }
  }

  &__step-number {
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__step-check {
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__step-title {
    font-size: 0.75rem;
    font-weight: 500;
    color: #9da9bb;
    text-align: center;
    transition: all 0.3s ease;
    margin-bottom: 0.25rem;
    line-height: 1.2;

    &--active {
      color: #0d6efd;
      font-weight: 600;
    }

    &--error {
      color: #dc3545;
      font-weight: 600;
    }
  }

  &__step-line {
    flex: 1;
    height: 2px;
    background-color: #e3e6ed;
    margin-bottom: 1.75rem;
    transition: all 0.3s ease;

    &--active {
      background-color: #0d6efd;
    }

    &--error {
      background-color: #dc3545;
    }
  }
}

.stepper-dark {
  background-color: #0f0f0f;
  border-bottom: 1px solid #272727;
  width: 100%;
}

.stepper-container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 1rem 1rem;
}

.stepper-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper-steps {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.stepper-step-wrapper {
  display: flex;
  align-items: center;
}

.stepper-step-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #272727;
  color: #9ca3af;
  transition: all 0.3s ease;

  &--active {
    background-color: #ff0000;
    color: white;
  }
}

.stepper-step-line {
  width: 4rem;
  height: 0.25rem;
  margin: 0 0.5rem;
  background-color: #272727;
  transition: all 0.3s ease;

  &--active {
    background-color: #ff0000;
  }
}
