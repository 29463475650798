.products-cont {
  .puduct-check-input {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.product-card-container {
  transition: all 0.3s ease;
  border-radius: 0.5rem;
  padding: 0.5rem;
  position: relative;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.3);
    transform: translateY(-2px);
  }
}

.product-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: white;
  border: 2px solid #e3e6ed;
  transition: all 0.2s ease;

  &:hover {
    border-color: #0d6efd;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.1);
  }

  &--checked {
    background: #0d6efd;
    border-color: #0d6efd;

    &:hover {
      background: #0b5ed7;
      border-color: #0b5ed7;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    color: white;
    font-size: 12px;
  }
}

.product-card {
  .border {
    position: relative;

    img {
      pointer-events: none;
      user-select: none;
      -webkit-user-drag: none;
    }
  }
}
