.order-items-cont {
  .order-item {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 4px;
    margin-bottom: 10px;
    .order-item-action-btn {
      margin-left: 10px;
    }
  }
}

.design-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
  position: relative;

  &__header {
    margin-bottom: 24px;
  }

  &__content {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 24px;

    @media (max-width: 475px) {
      grid-template-columns: 1fr;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__pricing {
    align-self: end;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #e2e8f0;
  }
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  &__info {
    flex: 1;
  }

  &__title {
    margin: 0 0 8px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__price {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c5282;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 200px;
  }

  &__quantity {
    label {
      display: block;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }

  &__buttons {
    display: flex;
    gap: 8px;
  }
}

.product-preview {
  &__image {
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__details {
    border-radius: 4px;
  }

  &__title {
    margin: 0 0 8px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__description {
    margin: 0;
    color: #4a5568;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}

.variation-selector {
  &__title {
    margin: 0 0 12px;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .form-select {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    font-size: 0.875rem;

    &:focus {
      border-color: #4299e1;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
    }

    &.is-invalid {
      border-color: #e53e3e;
    }
  }

  .invalid-feedback {
    margin-top: 4px;
    font-size: 0.875rem;
    color: #e53e3e;
  }
}

.upload-section {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__column {
    flex: 1;
  }

  &__item {
    padding: 16px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e2e8f0;

    &.is-invalid {
      border-color: #e53e3e;

      > .invalid-feedback {
        display: block;
      }
    }

    &--reversed {
      display: flex;
      flex-direction: column-reverse;
      gap: 8px;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }

  &__preview {
    text-align: center;
  }

  &__img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  &__filename {
    margin: 0;
    font-size: 0.875rem;
    color: #4a5568;
  }

  .invalid-feedback {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #e53e3e;
  }

  .dropzone {
    padding: 16px;
  }
}

.pricing-section {
  background: #f7fafc;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e2e8f0;

  &__details {
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;

    label {
      font-size: 0.875rem;
      color: #4a5568;
      font-weight: 500;
      white-space: nowrap;
    }

    input.form-control {
      max-width: 100%;
      width: auto;
    }
  }

  &__price {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c5282;
    padding: 8px;
    @media (max-width: 768px) {
      padding: unset;
    }

    &--total {
      font-size: 1.5rem;
      color: #2d3748;
      padding: 4px;
      min-width: 125px;
      text-align: right;

      @media (max-width: 768px) {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid #e2e8f0;
      }
    }
  }

  .form-control {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    font-size: 1rem;

    &:focus {
      border-color: #4299e1;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
    }
  }
}
